<template>
  <div class="edit-user-page">
    <div class="page-title">Edit User</div>
    <UserBaseForm
      :mode="'edit'"
      :userData="editingUser"
      @apply="onFormApply"
      @cancel="onFormCancel"
      @deleteUser="onDeleteUser"
    />
    <DeleteUserModal @deleted="onUserDeleted" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { notification } from 'ant-design-vue';

import UserBaseForm from './UserBaseForm.vue';
import DeleteUserModal from './DeleteUserModal.vue';

import api from '@/services/api';

export default {
  components: {
    UserBaseForm,
    DeleteUserModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const editingUser = computed(() => store.state.user.editingUser);
    if (editingUser.value && editingUser.value.id) {
      // continue
    } else {
      // go back to main page
      router.replace({ name: 'user-manager' });
    }

    const onFormApply = async (obj) => {
      const newObj = { ...obj };
      if (newObj.password) {
        delete newObj.password;
      }
      const result = await api.updateUser(
        {
          ...obj,
        },
        {
          id: editingUser.value.id,
          type: 'dom',
        }
      );
      if (result && result.message) {
        notification.success({
          message: 'Success',
          description: 'User data updated',
        });
        setTimeout(() => {
          router.replace({ name: 'user-manager' });
        }, 500);
      } else {
        notification.error({
          message: 'Error',
          description: 'User data update failed',
        });
      }
    };
    const onFormCancel = () => {
      console.log('FORM Cancel');
      router.replace({ name: 'user-manager' });
    };

    const onDeleteUser = () => {
      store
        .dispatch('user/setDeletingUser', {
          ...editingUser.value,
        })
        .then(() => {});
    };
    const onUserDeleted = async (username) => {
      if (username) {
        notification.success({
          message: 'Success',
          description: `User ${username} removed.`,
        });
        await store.dispatch('user/cancelDeletingUser');
        await store.dispatch('user/setEditingUser', {});
        router.replace({ name: 'user-manager' });
      } else {
        notification.error({
          message: 'Error',
          description: `User ${username} remove failed.`,
        });
        store.dispatch('user/cancelDeletingUser');
      }
    };

    return {
      editingUser,
      onFormApply,
      onFormCancel,
      onDeleteUser,
      onUserDeleted,
    };
  },
};
</script>

<style lang="scss" scoped>
.edit-user-page {
  padding: 36px 30px 18px 30px;
  height: 100%;
  overflow: auto;
  .page-title {
    font-size: 20px;
    font-weight: 500;
    color: #272b41;
    margin-bottom: 36px;
  }
}
</style>
